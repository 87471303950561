<template>
    <div>
        <van-popover
                class="header-popover"
                v-model="showPopover"
                trigger="click"
                :actions="option"
                @select="onSelect"
        >
            <template #reference>
                <div class="flex-ver-cen menu-select">
                    {{ selectMenu }}
                    <van-icon name="arrow-down" class="work-icon"/>
                </div>
            </template>
        </van-popover>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showPopover: false,
                selectMenu: null,
                option: this.$store.getters.headerRouters,
                path: null,
            };
        },

        watch: {
            $route: {
                handler(val) {
                    this.path = val.path;
                    this.selectMenu = this.option.find((ele) => ele.value == val.path).text;
                    this.children = this.option.find((ele) => ele.value == val.path).children;
                },
                // 深度观察监听
                deep: true,
                immediate: true,
            },
        },

        methods: {
            onSelect(action) {
                if (this.path == action.value) {
                    return;
                }
                this.selectMenu = action.text;
                this.children = action.children;
                this.$router.push({
                    path: action.value,
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
  .menu-select {
    font-size: 38px;
    font-weight: bold;
    color: #000000;

    .work-icon {
      font-size: 26px;
      margin-left: 10px;
    }
  }

  ::v-deep .van-dropdown-menu__bar {
    box-shadow: none;
  }

  ::v-deep .van-dropdown-item__option {
    text-align: center;
  }

  ::v-deep .van-cell__value {
    display: none;
  }

  ::v-deep .van-cell::after {
    display: none;
  }
</style>
